const modo_default = "cliente"

function reducer( state = modo_default, { type, payload } ){
    switch( type ){
        case 'cambiarModo': {
            return payload
        }
        default:
            return state
    }
}

export default reducer