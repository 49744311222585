import { reiniciarInterfaz } from "./cambiarEsquema"
import { reiniciarEsquema } from "./esquemaTablas"
const { Navigate } = require("react-router-dom")
const { default: guardarUsuario } = require("./guardarUsuario")

export const cerrarSesion = () => {
    return async dispatch => {
            dispatch(reiniciarEsquema())
            dispatch(guardarUsuario(false))
            dispatch(reiniciarInterfaz())
            return <Navigate to="/login" />
    }
}
