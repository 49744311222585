const cambiarEsquemaModular = objeto => {
    return {
        type: 'cambiarEsquemaModular',
        payload: objeto
    }
}

const reiniciarInterfaz = objeto => {
    return {
        type: 'reiniciarInterfaz',
        payload: false
    }
}

module.exports = {
    cambiarEsquemaModular,
    reiniciarInterfaz
}