const esquema_default = {
    actividad: { active: true, alias: "" },
    entregas: { active: true, alias: "" },
    estados_carga: { active: true, alias: "" },
    auditoria_app_movil: { active: true, alias: "" },
    rendimiento_proveedores: { active: true, alias: "" },
    operacion_en_curso: { active: true, alias: "" },
    ordenes: { active: true, alias: "" },
    mis_ordenes: { active: true, alias: "" },
    crear_nueva: { active: true, alias: "" },
    importar_excel: { active: true, alias: "" },
    importar_avanzado: { active: true, alias: "" },
    proveedores: { active: true, alias: "" },
    listado: { active: true, alias: "" },
    flota: { active: true, alias: "" },
    mi_flota: { active: true, alias: "" },
    monitoreo_flota: { active: true, alias: "" },
    alertas: { active: true, alias: "" },
    monitoreo_alertas: { active: true, alias: "" },
    informes_rastreo_conductor: { active: true, alias: "" },
    categorias: { active: true, alias: "" },
    automatizaciones: { active: true, alias: "" },
    clientes: { active: true, alias: "" },
    facturacion_cliente: { active: true, alias: "" },
    rutas: { active: true, alias: "" },
    mis_rutas: { active: true, alias: "" },
    invitaciones: { active: true, alias: "" },
    destinatarios: { active: true, alias: "" },
    bodega: { active: true, alias: "" },
    mis_bodegas: { active: true, alias: "" },
    expediciones: { active: true, alias: "" },
    etiquetas: { active: true, alias: "" },
    integraciones: { active: true, alias: "" }
  };

function reducer( state = esquema_default, { type, payload } ){
    switch( type ){
        case 'cambiarEsquemaModular': {
            return payload
        }
        case 'reiniciarInterfaz':
            return esquema_default
        default:
            return state
    }
}

export default reducer